import { useMutation } from "react-query";

import {
  createClinic,
  createReportTemplate,
  createReferringPhysician,
  // createNormalValueMeasurement,
} from "../services/form-api-service";

const createFunctionsByCollection = {
  clinics: createClinic,
  templates: createReportTemplate,
  referringPhysicians: createReferringPhysician,
  // 'normalValues': createNormalValueMeasurement,
};

export function useCreateMutation(collection, queryClient) {
  return useMutation(createFunctionsByCollection[collection], {
    onSuccess: () => queryClient.invalidateQueries(collection),
  });
}
