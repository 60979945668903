import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import store from "../store";
import { loadPatients } from "../actions";
import { filteredPatientsSelector } from "../selectors";
import { fetchPatients } from "../services/patient.service";

import PatientList from "./PatientList";
import PatientFilter from "./PatientFilter";
import ScrollTopButton from "./ScrollTopButton";

const PatientBrowser = ({
  filteredPatients,
  filterOptions: { activeFilters, nameFilter },
}) => {
  const [statusFilteredPatients, setStatusFilteredPatients] = useState([]);

  useEffect(() => {
    const updateReduxStore = async () => {
      // update redux store on page load, this keeps the data fresh
      const patients = await fetchPatients();
      store.dispatch(loadPatients(patients));
    };

    updateReduxStore();
  }, []);

  useEffect(() => {
    if (nameFilter) {
      setStatusFilteredPatients(filteredPatients);
    } else {
      if (activeFilters.includes("signedOnly")) {
        const signedPatients = filteredPatients.filter(
          (patient) => patient.studyStatus === "Signed"
        );
        setStatusFilteredPatients(signedPatients);
      } else {
        if (activeFilters.includes("archivedStudies")) {
          const archivedPatients = filteredPatients.filter(
            (patient) => patient.studyStatus === "Archived"
          );
          setStatusFilteredPatients(archivedPatients);
        } else {
          const unsignedPatients = filteredPatients.filter(
            (patient) =>
              patient.studyStatus !== "Signed" &&
              patient.studyStatus !== "Archived"
          );

          setStatusFilteredPatients(unsignedPatients);
        }
      }
    }
  }, [activeFilters, filteredPatients, nameFilter]);

  return (
    <div>
      <PatientFilter filteredPatients={statusFilteredPatients} />
      <PatientList patients={statusFilteredPatients} />
      <ScrollTopButton />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredPatients: filteredPatientsSelector(state),
  filterOptions: state.filterOptions,
});

export default connect(mapStateToProps)(PatientBrowser);
