import React, { useEffect, useState } from "react";
import { Form, Formik, useField } from "formik";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { PrimaryButton, SecondaryButton } from "./Buttons";

import "./ManageReferringPhysicians.css";

import { useGetAllQuery } from "../hooks/useGetAllQuery";
import { useCreateMutation } from "../hooks/useCreateMutation";
import { useUpdateMutation } from "../hooks/useUpdateMutation";

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className="inputLabelSet">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="inputWithError">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        <input {...field} {...props} />
      </div>
    </div>
  );
};

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="inputLabelSet">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="inputWithError">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        <select
          {...field}
          {...props}
          style={{ height: "42px", borderRadius: "14px" }}
        />
      </div>
    </div>
  );
};

const AddPhysician = ({ data }) => {
  const queryClient = useQueryClient();
  const [showClinicForm, setShowClinicForm] = useState(false);
  const createClinicMutation = useCreateMutation("clinics", queryClient);
  const updateClinicMutation = useUpdateMutation("clinics", queryClient);

  const initialValues = {
    firstName: "",
    lastName: "",
    clinicName: "",
    street: "",
    city: "",
    province: "",
    country: "",
    postalCode: "",
    phoneNumber: "",
    faxNumber: "",
    logo: "https://ar.synapticnetworks.com/logo/ARLogoIcon.png",
    clinicId: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    clinicId: showClinicForm
      ? Yup.string()
      : Yup.string().required("Please select a clinic"),
    clinicName: showClinicForm
      ? Yup.string().required("Name is required")
      : Yup.string(),
    street: showClinicForm
      ? Yup.string().required("Street no. and name is required")
      : Yup.string(),
    city: showClinicForm
      ? Yup.string().required("City is required")
      : Yup.string(),
    province: showClinicForm
      ? Yup.string().required("Province is required")
      : Yup.string(),
    postalCode: showClinicForm
      ? Yup.string().required("Postal code is required")
      : Yup.string(),
    country: showClinicForm
      ? Yup.string().required("Country is required")
      : Yup.string(),
    phoneNumber: showClinicForm
      ? Yup.string().required("Phone number is required")
      : Yup.string(),
    faxNumber: showClinicForm
      ? Yup.string().required("Fax number is required")
      : Yup.string(),
    logo: Yup.string(),
  });

  const toggleShowClinic = () => {
    setShowClinicForm(showClinicForm ? false : true);
  };

  return (
    <div className="addPhysicianClinic">
      <h2>Add physician {showClinicForm ? " and clinic" : ""}</h2>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (showClinicForm) {
              const bodyData = {
                clinicName: values.clinicName,
                street: values.street,
                city: values.city,
                province: values.province,
                country: values.country,
                postalCode: values.postalCode,
                phoneNumber: values.phoneNumber,
                faxNumber: values.faxNumber,
                logo: values.logo,
                referringPhysicians: [
                  {
                    firstName: values.firstName,
                    lastName: values.lastName,
                  },
                ],
              };

              createClinicMutation.mutate(bodyData, {
                onSuccess: () => {
                  setShowClinicForm(false);
                  resetForm({});
                },
              });
            } else {
              const updatedClinicIndex = data.findIndex(
                (clinic) => clinic.clinicId === values.clinicId
              );

              const bodyData = {
                referringPhysicians: [
                  ...data[updatedClinicIndex]["referringPhysicians"],
                  {
                    firstName: values.firstName,
                    lastName: values.lastName,
                  },
                ],
              };

              updateClinicMutation.mutate(
                { id: values.clinicId, body: bodyData },
                {
                  onSuccess: () => {
                    setShowClinicForm(false);
                    resetForm({});
                  },
                }
              );
            }
          }}
        >
          <Form>
            <div>
              <div className="physicianFields">
                <TextInput
                  name="firstName"
                  label="First name"
                  placeholder="First name"
                />
                <TextInput
                  name="lastName"
                  label="Last name"
                  placeholder="Last name"
                />
                <p style={{ display: "flex", flexDirection: "column" }}>
                  {showClinicForm ? "" : `Can't find the clinic?`}&nbsp;&nbsp;
                  <SecondaryButton onClick={toggleShowClinic}>
                    {showClinicForm ? "Hide clinic form" : "Add a new clinic"}
                  </SecondaryButton>
                </p>
                {!showClinicForm && (
                  <SelectInput label="Clinic" name="clinicId">
                    <option value="">Select a clinic</option>
                    {data.map((clinic) => (
                      <option key={clinic.clinicId} value={clinic.clinicId}>
                        {clinic.clinicName}
                      </option>
                    ))}
                  </SelectInput>
                )}
              </div>
              {showClinicForm && (
                <div className="clinicFields">
                  <TextInput
                    name="clinicName"
                    label="Clinic name"
                    placeholder="Clinic name"
                  />
                  <TextInput
                    name="street"
                    label="Street no. and name"
                    placeholder="Street no. and name"
                  />
                  <TextInput name="city" label="City" placeholder="City" />
                  <TextInput
                    name="postalCode"
                    label="Postal code"
                    placeholder="Postal code - X1X 1X1"
                  />
                  <TextInput
                    name="province"
                    label="Province"
                    placeholder="Province"
                  />
                  <TextInput
                    name="country"
                    label="Country"
                    placeholder="Country"
                  />
                  <TextInput
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="Phone number"
                  />
                  <TextInput
                    name="faxNumber"
                    label="Fax number"
                    placeholder="Fax number"
                  />
                </div>
              )}
            </div>
            <div className="signUpSection authActionSection">
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const ClinicList = ({ data }) => {
  return (
    <div style={{ margin: "2vh 0 5vh" }}>
      <h2>Physicians</h2>
      <div className="physiciansContainer">
        <div className="physicianRow physicianHeaderRow">
          <p>Physician name</p>
          <p>Clinic name</p>
          <p>Address</p>
          <p>Phone number</p>
          <p>Fax number</p>
        </div>
        {data.map((clinic) =>
          clinic.referringPhysicians.map((physician) => {
            const address = `${clinic.street}, ${clinic.city}, ${clinic.province}, ${clinic.postalCode}, ${clinic.country}`;

            return (
              <div className="physicianRow" key={physician._id}>
                <p>
                  {physician.lastName}, {physician.firstName}
                </p>
                <p>{clinic.clinicName}</p>
                <p>{address}</p>
                <p>{clinic.phoneNumber}</p>
                <p>{clinic.faxNumber}</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const ManageClinics = () => {
  const [showMainForm, setShowMainForm] = useState(false);
  const { data } = useGetAllQuery("clinics");

  const toggleShowMainForm = () => {
    setShowMainForm(showMainForm ? false : true);
  };

  if (!data) {
    return null;
  }

  return (
    <div style={{ width: "95vw", margin: "5vh auto", minHeight: "60vh" }}>
      <h1 style={{ paddingBottom: "2rem" }}>Manage Referring Physicians</h1>
      {!showMainForm && (
        <PrimaryButton onClick={toggleShowMainForm}>
          Add physician
        </PrimaryButton>
      )}
      {showMainForm && <AddPhysician data={data} />}
      <ClinicList data={data} />
    </div>
  );
};

export default ManageClinics;
