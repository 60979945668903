import Auth from "@aws-amplify/auth";

export const getCurrentAuthenticatedUser = async () => {
  // const user = await Auth.currentAuthenticatedUser({ bypassCache: true }) // bypassCache should be kept true during dev for easier testing
  const user = await Auth.currentAuthenticatedUser({})
    .then((user) => user)
    .catch((err) => {
      console.log("err: ", err);
    });

  return user;
};

export const getCurrentSession = async () => {
  const session = await Auth.currentSession()
    .then((data) => data)
    .catch((err) => console.log(err));

  return session;
};

// export const getAllUsers = async () => {
//   const users = await Auth.listUsers({})
//                         .then((users) => users)
//                         .catch((err) => {
//                           console.log("err: ", err);
//                         });

//   return users;
// }
