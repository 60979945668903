import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Auth, Storage } from "aws-amplify";
import { Formik, Form, useField, useFormikContext } from "formik";

import { PrimaryButton } from "../Buttons";
import { AuthWrapper, TextInput } from "./AuthComponents";

async function confirmSignUp({ username, code }) {
  try {
    const result = await Auth.confirmSignUp(username, code);
    return result;
  } catch (error) {
    console.log("error confirming sign up", error);
    return error;
  }
}

const SignUpPage = () => {
  const [signUpStage, setSignUpStage] = useState(""); // signup confirm, and signup done are the 2 states
  const clinicLogo = process.env.REACT_APP_CLINICLOGO_URL
    ? process.env.REACT_APP_CLINICLOGO_URL
    : "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ARLogoIcon.png";

  const initialValues = {
    email: "",
    password: "",
    phone_number: "",
    role: "",
    clinicName: "",
    clinicStreet: "",
    clinicCity: "",
    clinicProvince: "",
    clinicPostalCode: "",
    clinicCountry: "",
    clinicPhoneNumber: "",
    clinicFaxNumber: "",
    clinicLogo: clinicLogo,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    // https://stackoverflow.com/questions/55451304/formik-yup-password-strength-validation-with-react
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 characters minimum")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one uppercase, one lowercase, one number and one special case character."
      ),
    // https://stackoverflow.com/questions/273141/regex-for-numbers-only
    phone_number: Yup.string().required("Phone number is required"),
    /*      
      .matches(/^\d+$/, "Phone number can contain only numbers")
      .length(10, "Phone number should be 10 characters long"),
*/
    role: Yup.string().required("Role is required"),
    clinicName: Yup.string().required("Clinic name is required"),
    clinicStreet: Yup.string().required("Clinic address street is required"),
    clinicCity: Yup.string().required("Clinic address city is required"),
    clinicProvince: Yup.string().required(
      "Clinic address province is required"
    ),
    clinicPostalCode: Yup.string().required("Postal code is required"),
    // .matches(/^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/, "Postal code must be in the format X1X 1X1"), // Postal code regex for Canada
    clinicCountry: Yup.string().required("Clinic country is required"),
    clinicPhoneNumber: Yup.string().required("Clinic phone number is required"),
    clinicFaxNumber: Yup.string().required("Clinic fax number is required"),
    clinicLogo: Yup.string(),
  });

  async function signUp({ email, password, phone_number, role, ...clinic }) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: `+1${phone_number}`,
          "custom:role": role,
          "custom:clinicName": clinic.clinicName,
          "custom:clinicStreet": clinic.clinicStreet,
          "custom:clinicCity": clinic.clinicCity,
          "custom:clinicProvince": clinic.clinicProvince,
          "custom:clinicPostalCode": clinic.clinicPostalCode,
          "custom:clinicCountry": clinic.clinicCountry,
          "custom:clinicPhoneNumber": `+1${clinic.clinicPhoneNumber}`,
          "custom:clinicFaxNumber": `+1${clinic.clinicFaxNumber}`,
          "custom:clinicLogo": clinic.clinicLogo,
        },
      });

      return true;
    } catch (error) {
      console.log("error signing up:", typeof error, error);
      return false;
    }
  }

  if (signUpStage === "signup confirm") {
    return <SignUpConfirmation setSignUpStage={setSignUpStage} />;
  }

  if (signUpStage === "signup done") {
    return <Redirect to="/signin" />;
  }

  return (
    <AuthWrapper heading="Sign up">
      <div className="signUpForm authForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const result = await signUp(values);
            if (result) {
              setSignUpStage("signup confirm");
            }
          }}
        >
          <Form>
            <div className="formInputs">
              <div>
                <TextInput
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Email address"
                />
                <TextInput
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                />
                <TextInput
                  name="phone_number"
                  label="Phone number"
                  placeholder="Phone number"
                />
                <SelectInput label="Role" name="role">
                  <option value="">Select a role</option>
                  <option value="sonographer">Sonographer</option>
                  <option value="cardiologist">Cardiologist</option>
                </SelectInput>
              </div>
              <div>
                <TextInput
                  name="clinicName"
                  label="Clinic name"
                  placeholder="Clinic name"
                />
                <TextInput
                  name="clinicStreet"
                  label="Clinic address street"
                  placeholder="Clinic street no. and name"
                />
                <TextInput
                  name="clinicCity"
                  label="Clinic address city"
                  placeholder="Clinic city"
                />
                <TextInput
                  name="clinicPostalCode"
                  label="Clinic address postal code"
                  placeholder="Clinic postal code - X1X 1X1"
                />
                <TextInput
                  name="clinicProvince"
                  label="Clinic address province"
                  placeholder="Clinic province"
                />
                <TextInput
                  name="clinicCountry"
                  label="Clinic address country"
                  placeholder="Clinic country"
                />
                <TextInput
                  name="clinicPhoneNumber"
                  label="Clinic phone number"
                  placeholder="Clinic phone number"
                />
                <TextInput
                  name="clinicFaxNumber"
                  label="Clinic fax number"
                  placeholder="Clinic fax number"
                />
                {/* <FileInput /> */}
              </div>
            </div>
            <div className="signUpSection authActionSection">
              <PrimaryButton type="submit">CREATE ACCOUNT</PrimaryButton>
              <p>
                Have an account?&nbsp;
                <Link to="/signin">Sign in</Link>
              </p>
            </div>
          </Form>
        </Formik>
      </div>
    </AuthWrapper>
  );
};

const FileInput = () => {
  const { setFieldValue } = useFormikContext();
  const [file, setFile] = useState({});

  useEffect(() => {
    const uploadImage = async () => {
      if (file.type && file.type.includes("image/")) {
        try {
          const result = await Storage.put(`${file.name}_${new Date()}`, file, {
            // level: 'protected',
            contentType: file.type, // contentType is optional
          });

          console.log("upload result: ", result);
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
      }
    };

    uploadImage();
  }, [file]);

  // useEffect(() => {
  //   setFieldValue('clinicLogo', "");
  // }, []);

  return (
    <div className="inputLabelSet">
      <label htmlFor="clinicLogo">Clinic logo</label>
      <div className="inputWithError">
        <input
          type="file"
          accept="image/*"
          name="clinicLogo"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
    </div>
  );
};

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="inputLabelSet">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="inputWithError">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        <select
          {...field}
          {...props}
          style={{ height: "42px", borderRadius: "14px" }}
        />
      </div>
    </div>
  );
};

const SignUpConfirmation = ({ setSignUpStage }) => {
  const [message, setMessage] = useState("");

  const initialValues = {
    username: "",
    code: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    code: Yup.string()
      .required("Code is required")
      .matches(/^\d+$/, "Code can contain only numbers"),
  });

  return (
    <AuthWrapper heading="Confirm account">
      <div className="confirmForm authForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const result = await confirmSignUp(values);
            console.log("result: ", result);
            if (result === "SUCCESS") {
              setMessage(
                "Registration successfully confirmed. Please sign in."
              );

              setTimeout(() => {
                setSignUpStage("signup done");
              }, 2500);
            } else if (result.message) {
              setMessage(result.message);
            }
          }}
        >
          <Form>
            <TextInput
              name="username"
              type="email"
              label="Email"
              placeholder="Email address"
            />
            <TextInput name="code" label="Code" placeholder="Code" />
            <div className="authActionSection alignButtonRight">
              <PrimaryButton type="submit">CONFIRM</PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
      <p
        style={{
          color:
            message === "Registration successfully confirmed. Please sign in."
              ? "green"
              : "red",
        }}
      >
        {message}
      </p>
    </AuthWrapper>
  );
};

export default SignUpPage;
