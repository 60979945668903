import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Hub } from "aws-amplify";

import { Flex, Icon, Heading, Box, Grid } from "@chakra-ui/react";
import { MdFavoriteBorder as HeartIcon } from "react-icons/md";

import { getCurrentAuthenticatedUser } from "../services/auth.service";

import AccountButton from "./AccountButton";

const styles = {
  logo: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  logoContainer: {
    background: "#fff",
    borderRadius: "50%",
    padding: ".25rem",
    margin: ".25rem",
    height: "95px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 400,
    color: "white",
    marginLeft: ".5rem",
    textTransform: "capitalize",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
};

const Topbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUser = async () => {
      const userData = await getCurrentAuthenticatedUser();

      if (userData && userData.attributes) {
        setIsAuthenticated(true);
        setUser(userData.attributes);
      }
    };

    getUser();
  }, []);

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        setIsAuthenticated(true);
        setUser(data.attributes);
        break;
      case "signOut":
        setIsAuthenticated(false);
        break;
      default:
        break;
    }
  });

  return (
    <Box as="header" bg="#3f51b5" color="white" p="0 2vw">
      <Flex align="center" justify="space-evenly" minHeight="10vh">
        <div>
          <Flex to="/" as={RouterLink} fontSize="1rem">
            <Icon as={HeartIcon} w="6" h="6" />
            <Heading as="h1" style={styles.mainTitle}>
              Echo Augmented Reporting
            </Heading>
          </Flex>
        </div>
        <div style={styles.logo}>
          <Flex to="/" as={RouterLink} fontSize="1rem">
            {isAuthenticated && (
              <div style={styles.logoContainer}>
                <img
                  src={`${process.env.REACT_APP_CLINICLOGO_URL}`}
                  alt="logo"
                  width="90"
                />
              </div>
            )}
          </Flex>
        </div>
        <div>
          {isAuthenticated && (
            <Grid gap="1rem" gridTemplateColumns="auto 1fr">
              <Flex align="center">{user.email}</Flex>
              <AccountButton user={user} />
            </Grid>
          )}
        </div>
      </Flex>
    </Box>
  );
};

export default Topbar;
