import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { MdMarkunread, MdArchive } from "react-icons/md";
import { GrInProgress, GrDocumentPdf } from "react-icons/gr";
import { StarIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { Avatar, Box, Button, Flex, Grid, IconButton } from "@chakra-ui/react";

import { updateFavourites } from "../actions";
import { isFavouriteSelector, allGlassesSelector } from "../selectors";
import { customDateFormat, removeOrAddItemFromArray } from "../utils/util";

const styles = {
  diagnosisList: {
    paddingLeft: 0,
  },
  card: {
    width: "20rem",
    margin: ".5rem",
    backgroundColor: "#fff",
  },
  actions: {
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    fontWeight: "700",
  },
  subHeader: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#0000008A",
  },
  borderStyles: {
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),  0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
};

const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;
const deployASE = process.env.REACT_APP_PREMIUM_ASE;

const PatientItem = ({
  patient,
  displayStyle,
  favourite,
  favourites,
  updateFavourites,
}) => {
  const CustomCardHeader = () => {
    return (
      <Flex p="1rem" justify="flex-start" align="center">
        <Avatar
          name={patient.PatientName}
          style={{
            color:
              parseInt(patient.abnTotal) === 0
                ? "rgb(255, 255, 255)"
                : "rgba(1, 1, 1, .8)",
            backgroundColor:
              parseInt(patient.abnTotal) === 0
                ? "green"
                : parseInt(patient.abnTotal) === 2
                ? "red"
                : "orange",
          }}
        />
        <Flex pl="1rem" direction="column" width="80%">
          <h1 style={styles.title}>{patient.PatientName}</h1>
          <span style={styles.subHeader}>
            DoB: {customDateFormat(patient.PatientBirthDate)}
            <br />
            Gender: {patient.PatientSex}
            <br />
            Series Date:{" "}
            {customDateFormat(
              !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
            )}
            <br />
            Study Type:{" "}
            {patient.ProtocolName?.toLowerCase().includes("stress")
              ? "Physiological Stress"
              : patient.ProtocolName}{" "}
            {patient.isContrastAgentUsed ? " (CONTRAST)" : ""}
            <br />
            Reading Physician: {patient.PhysiciansOfRecord}
            {deployEnvironment === "mkmed" && patient.StationName && <br />}
            {deployEnvironment === "mkmed" &&
              patient.StationName &&
              `Machine: ${patient.StationName}`}
          </span>
        </Flex>
      </Flex>
    );
  };

  const CustomCardContent = () => {
    return (
      <Box p={displayStyle === "list" ? "1rem" : ".5rem 1rem"}>
        <ul style={{ ...styles.diagnosisList, padding: "auto 0" }}>
          Study Status: &nbsp;
          <span style={styles.diagnosisList}>{patient.studyStatus}</span>
          <br />
          {deployASE === "true" ? (
            <>
              Measurements:
              <span
                style={{
                  ...styles.diagnosisList,
                  color:
                    parseInt(patient.abnMeasure) === 1 ||
                    patient.EFSimpsonsQ !== "Normal"
                      ? "red"
                      : "green",
                }}
              >
                {" "}
                {/*            {parseInt(patient.abnMeasure) === 1 ? "Abnormal" : "Normal"}
                 */}
                {!patient.PatientSex || !patient.PatientBirthDate
                  ? "Missing Parameters"
                  : parseInt(patient.abnMeasure) === 1 ||
                    patient.EFSimpsonsQ !== "Normal"
                  ? "Abnormal"
                  : "Normal"}
              </span>
              <br />
              Systolic Function:
              <span
                style={{
                  ...styles.diagnosisList,
                  color: patient.EFSimpsonsQ !== "Normal" ? "red" : "green",
                }}
              >
                {" "}
                {patient.EFSimpsonsQ +
                  " " +
                  patient.EFSimpsons +
                  "% by Simpson's EF"}
              </span>
              <br />
              Diastolic Function:
              <span
                style={{
                  ...styles.diagnosisList,
                  color: parseInt(patient.DFADiagnosis) === 1 ? "red" : "green",
                }}
              >
                {" "}
                {patient.DFA}
              </span>
              <br />
            </>
          ) : (
            ""
          )}
          {/*         {parseInt(patient.abnDiagnosis) === 1
              ? patient.DFADiagnosis === 1
                ? "DFA: " +
                  patient.DFA +
                  " " +
                  (patient.LVMDiagnosis === 1 ? "| LVM: " + patient.LVM : "")
                : "LVM: " + patient.LVM
              : "Normal"}
*/}
          Comments: &nbsp;
          <span style={styles.diagnosisList}>{patient.Comments}</span>
        </ul>
      </Box>
    );
  };

  const handleClickViewPDF = () => {
    const baseURL = window.location.origin;

    setTimeout(() => {
      window.open(
        `${baseURL}/report/${patient.SeriesInstanceUID}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      );
    }, 500);
  };

  const CustomCardActions = () => {
    return (
      <Flex p="1rem" justify="space-between" align="center">
        <Button
          variant="ghost"
          onClick={() => {
            updateFavourites(
              removeOrAddItemFromArray(patient.slug, favourites)
            );
          }}
        >
          {favourite ? (
            <StarIcon w={5} h={5} color="yellow.400" />
          ) : (
            <StarIcon w={5} h={5} color="gray.300" />
          )}
        </Button>

        {patient.studyStatus === "Signed" ? (
          <IconButton
            onClick={handleClickViewPDF}
            aria-label="Open PDF report"
            icon={<GrDocumentPdf />}
          />
        ) : (
          <IconButton
            variant="ghost"
            style={{ cursor: "default" }}
            aria-label={
              patient.studyStatus === "In progress"
                ? "In progress"
                : "Waiting to be read"
            }
            icon={
              patient.studyStatus === "In progress" ? (
                <GrInProgress />
              ) : (
                <MdMarkunread />
              )
            }
            _hover={{ background: "white" }}
          />
        )}
        <IconButton
          onClick={() => console.log("archive")}
          aria-label="Archive study"
          icon={<MdArchive />}
        />
        <Button
          as={RouterLink}
          variant="ghost"
          color="purple.600"
          to={`/patients/${patient.slug}`}
          style={{ textDecoration: "none", paddingRight: 0, fontSize: "15px" }}
        >
          OPEN REPORT
          <ChevronRightIcon w={10} h={10} />
        </Button>
      </Flex>
    );
  };

  if (!patient) return null;

  if (displayStyle === "list") {
    return (
      <Grid
        w="70vw"
        m=".75rem auto"
        templateColumns="1fr 1fr 1fr"
        style={{
          ...styles.borderStyles,
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        }}
      >
        <CustomCardHeader />
        <CustomCardContent />
        <CustomCardActions />
      </Grid>
    );
  }

  return (
    <Flex
      direction="column"
      style={{
        ...styles.card,
        ...styles.borderStyles,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      }}
    >
      <CustomCardHeader />
      <CustomCardContent />
      <CustomCardActions />
    </Flex>
  );
};

const mapStateToProps = (state, ownProps) => ({
  favourite: isFavouriteSelector(state, ownProps),
  favourites: state.favourites,
  allGlasses: allGlassesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateFavourites: bindActionCreators(updateFavourites, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientItem);
