import compact from "lodash/compact";
import isArray from "lodash/isArray";
import { buildFilter } from "../filterConfig";
import * as rules from "./filterRules";

/**
 * Apply a single filter to a set of patients
 * @param {*} patients
 * @param {*} filter
 */
export function applyFilter(patients, filter) {
  return patients.filter((patient) => {
    // console.log(patient);
    return rules[filter.rule.toString()](patient, filter);
  });
}

/**
 * Apply multiple filters, one after the other
 * @param {*} patients
 * @param {*} filters
 */
export function applyFilters(patients, filters = []) {
  if (!isArray(filters)) filters = [filters];

  return compact(filters).reduce(
    (acc, filter) => [...applyFilter(acc, filter)],
    [...patients]
  );
}

// builds an array of filters based on the users current filter options.
export function filtersFromUserOptions(state) {
  const { filterOptions, ...otherState } = state;

  // Build initial filters based on the current "activeFilters"
  const filters = filterOptions.activeFilters.map((filterRule) => {
    return buildFilter(filterRule)(filterOptions, otherState);
  });

  // Add in the special "Name filter", which is a filter but not in the
  // typical sense (so, it's not on the menu for example)
  if (filterOptions.nameFilter) {
    filters.push({
      rule: "nameIncludes",
      searchText: filterOptions.nameFilter,
    });
  }

  return filters;
}
