import React from "react";
import useScroll from "../hooks/useScroll";

import { IconButton, Slide } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const styles = {
  scrollTopButton: {
    position: "fixed",
    bottom: "1rem",
    right: "1rem",
    zIndex: 10000,
    pointerEvents: "auto",
  },
  hidden: {
    display: "none",
  },
};

const ScrollTopButton = () => {
  const scrollPos = useScroll();
  const visible = scrollPos > 100;

  return (
    <Slide in={visible} direction="up" style={{ pointerEvents: "none" }}>
      <IconButton
        w={12}
        h={12}
        variant="ghost"
        icon={<ArrowUpIcon color="white" w={6} h={6} />}
        borderRadius="50%"
        background="#78909c"
        onClick={scrollToTop}
        aria-label="Return to top"
        style={styles.scrollTopButton}
        _hover={{ background: "#455a64" }}
      />
    </Slide>
  );
};

export default ScrollTopButton;
