import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Hub } from "@aws-amplify/core";

import { Spinner } from "@chakra-ui/react";

import { getCurrentAuthenticatedUser } from "../services/auth.service";

import SignIn from "./Authentication/SignIn";

const PrivateRoute = ({ component, ...args }) => {
  const [user, setUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      if (!loggedIn) {
        const user = await getCurrentAuthenticatedUser();

        if (user && user.attributes) {
          setUser(user);
        }
        setIsLoading(false);
      }
    };
    getUser();
  }, [loggedIn]);

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        setUser({ attributes: data.attributes });
        setLoggedIn(true);
        setIsLoading(false);
        break;
      case "signOut":
        setUser({ attributes: null });
        setIsLoading(false);
        break;
      default:
        break;
    }
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner size="xl" color="blue.400" />
      </div>
    );
  }

  if ((user && user.attributes) || loggedIn) {
    return <Route component={component} {...args} />;
  }
  return <Route component={SignIn} {...args} />;
};

export default PrivateRoute;
