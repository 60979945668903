import { createSelector } from "reselect";
import { get, uniq, compact } from "lodash";

import {
  applyFilters,
  applyFilter,
  filtersFromUserOptions,
} from "../utils/filter";

// TODO: Use these in the `mapStateToProps` functions accross application
// rather than accessing state directly?
const allPatientsSelector = (state) => state.db.patients;
export const allGlassesSelector = (state) => state.db.glasses;
// const barSelector = (state) => state.bar;
const favouritesSelector = (state) => state.favourites;

const currentSlugFromUrlSelector = (_, props) =>
  get(props, "match.params.slug");

const currentSlugFromPatientPropSelector = (_, props) =>
  get(props, "patient.slug");

// figures out the patient in question from either the page URL
// or, if that doesn't exist, it looks for a "patient" prop and
// gets the slug from that.
const currentSlugSelector = createSelector(
  currentSlugFromUrlSelector,
  currentSlugFromPatientPropSelector,
  (urlSlug, PatientPropSlug) => urlSlug || PatientPropSlug
);

// isFavouriteSelector
// Derives whether the current patient is a favourite
export const isFavouriteSelector = createSelector(
  favouritesSelector,
  currentSlugSelector,
  (favourites, patientSlug) => favourites.includes(patientSlug)
);

// filtersSelector
// Derives the currently applied filters
const filtersSelector = (state) => filtersFromUserOptions(state);

export const currentPatientSelector = createSelector(
  allPatientsSelector,
  currentSlugSelector,
  (patients, slug) => patients.find((c) => c.slug === slug)
);

// filteredPatientsSelector
// Derives the currently filtered patients
export const filteredPatientsSelector = createSelector(
  allPatientsSelector,
  filtersSelector,
  (patients, filter) => applyFilters(patients, filter)
  // Sorting based on abnTotal has been disabled.
  // Replaced with reverse chronological sorting.
  // .sort((a, b) =>
  //   a.abnTotal > b.abnTotal ? 1 : -1
  // )
);

/*
// makeableCocktailsSelector
// Derives the currently makeable cocktails based on bar contents
export const makeableCocktailsSelector = createSelector(
  allPatientsSelector,
  barSelector,
  (cocktails, bar) =>
    applyFilter(cocktails, {
      rule: "makeableFrom",
      ingredients: bar,
    })
);
*/

// allCategoriesSelector
// Derives an array of all the categories
export const allCategoriesSelector = createSelector(
  allPatientsSelector,
  (patients) => compact(uniq(patients.map((c) => c.category)))
);
