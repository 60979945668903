import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Formik, useField } from "formik";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";

import { PrimaryButton, SecondaryButton } from "./Buttons";

import { useGetAllQuery } from "../hooks/useGetAllQuery";
import { useCreateMutation } from "../hooks/useCreateMutation";
import { useUpdateMutation } from "../hooks/useUpdateMutation";

const AddReportTemplate = ({ reportTemplates, formState, setFormState }) => {
  const queryClient = useQueryClient();
  const [templateData, setTemplateData] = useState({});
  const [templateNameError, setTemplateNameError] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

  const createTemplateMutation = useCreateMutation("templates", queryClient);
  const updateTemplateMutation = useUpdateMutation("templates", queryClient);

  useEffect(() => {
    if (formState.templateId) {
      const reportTemplateData = reportTemplates.find(
        (et) => et.templateId === formState.templateId
      );
      setTemplateData(reportTemplateData);
    }
  }, [reportTemplates, formState.templateId]);

  useEffect(() => {
    if (formState.action === "create") {
      if (formState.templateType === "free") {
        setTemplateData({
          templateName: "",
          templateText: {
            testConclusion: "",
            contrastAgentNotes: "",
            lvSizeAndFunction: "",
            rvPaPv: "",
            laAndRa: "",
            aortaAndAv: "",
            mvAndTv: "",
            vessels: "",
          },
        });
      } else if (formState.templateType === "stress") {
        setTemplateData({
          templateName: "",
          templateText: {
            testConclusion: "",
            contrastAgentNotes: "",
            stressTestProtocol: "",
            reasonForStopping: "",
            functionalCapacity: "",
            hemodynamicExerciseResponse: "",
            baselineECG: "",
            peakExerciseECG: "",
            recoveryECG: "",
            restingStressEcho: "",
            peakExerciseStressEcho: "",
            recoveryStressEcho: "",
          },
        });
      } else {
        setTemplateData({
          templateName: "",
          templateText: {
            testConclusion: "",
            pharmacologicalNotes: "",
            contrastAgentNotes: "",
            stressTestProtocol: "",
            reasonForStopping: "",
            functionalCapacity: "",
            hemodynamicExerciseResponse: "",
            restingECGEcho: "",
            lowDoseDobutamineECGEcho: "",
            peakDoseDobutamineECGEcho: "",
            recoveryECGEcho: "",
          },
        });
      }
    }
  }, [formState.action]);

  const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
      <Box p="1rem">
        <label htmlFor={props.id || props.name}>{label}</label>
        <div>
          <Input
            {...field}
            {...props}
            width="20vw"
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            padding="0.5rem 0.75rem"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
        </div>
        {meta.touched && meta.error ? (
          <Box color="red">{meta.error}</Box>
        ) : null}
        {templateNameError && (
          <Box color="red">The Report template name has to be unique.</Box>
        )}
      </Box>
    );
  };

  const TextAreaField = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field] = useField(props);

    return (
      <Box p="1rem">
        {(formState.action !== "view" ||
          (formState.action === "view" && formState.templateId)) && (
          <label htmlFor={props.id || props.name}>{label}</label>
        )}
        <div>
          {formState.action === "view" ? (
            <p>{templateData?.templateText?.[props.name]}</p>
          ) : (
            <Textarea
              rows="5"
              {...field}
              {...props}
              boxSizing="border-box"
              borderRadius="14px"
              border="2px solid #d3d3d3"
              padding="0.5rem 0.75rem"
              _focus={{ outline: "none", border: "2px solid #0038ff" }}
            />
          )}
        </div>
      </Box>
    );
  };

  const commonInitialValues = {
    testConclusion: templateData?.templateText?.testConclusion,
    contrastAgentNotes: templateData?.templateText?.contrastAgentNotes,
    templateName:
      formState.action === "copy"
        ? ""
        : templateData.templateName
        ? templateData.templateName
        : "",
  };

  const freeFormInitialValues = {
    lvSizeAndFunction: templateData?.templateText?.lvSizeAndFunction,
    rvPaPv: templateData?.templateText?.rvPaPv,
    laAndRa: templateData?.templateText?.laAndRa,
    aortaAndAv: templateData?.templateText?.aortaAndAv,
    mvAndTv: templateData?.templateText?.mvAndTv,
    vessels: templateData?.templateText?.vessels,
  };

  const commonStressEchoInitialValues = {
    stressTestProtocol: templateData?.templateText?.stressTestProtocol,
    reasonForStopping: templateData?.templateText?.reasonForStopping,
    functionalCapacity: templateData?.templateText?.functionalCapacity,
    hemodynamicExerciseResponse:
      templateData?.templateText?.hemodynamicExerciseResponse,
  };

  const exerciseInitialValues = {
    baselineECG: templateData?.templateText?.baselineECG,
    peakExerciseECG: templateData?.templateText?.peakExerciseECG,
    recoveryECG: templateData?.templateText?.recoveryECG,
    restingStressEcho: templateData?.templateText?.restingStressEcho,
    peakExerciseStressEcho: templateData?.templateText?.peakExerciseStressEcho,
    recoveryStressEcho: templateData?.templateText?.recoveryStressEcho,
  };

  const dobutamineInitialValues = {
    restingECGEcho: templateData?.templateText?.restingECGEcho,
    lowDoseDobutamineECGEcho:
      templateData?.templateText?.lowDoseDobutamineECGEcho,
    peakDoseDobutamineECGEcho:
      templateData?.templateText?.peakDoseDobutamineECGEcho,
    recoveryECGEcho: templateData?.templateText?.recoveryECGEcho,
    pharmacologicalNotes: templateData?.templateText?.pharmacologicalNotes,
  };

  const initialValues =
    formState.templateType === "free"
      ? { ...commonInitialValues, ...freeFormInitialValues }
      : formState.templateType === "stress"
      ? {
          ...commonInitialValues,
          ...commonStressEchoInitialValues,
          ...exerciseInitialValues,
        }
      : {
          ...commonInitialValues,
          ...commonStressEchoInitialValues,
          ...dobutamineInitialValues,
        };

  const validationSchema = Yup.object({
    templateName: Yup.string().required("Template name is required"),
  });

  const headingText = {
    edit: `Edit "${templateData.templateName}" Report Template`,
    copy: `Create copy of "${templateData.templateName}" Report Template`,
    create: `Add a new ${
      formState.templateType === "free"
        ? '"free form"'
        : formState.templateType === "stress"
        ? '"physiological stress echo"'
        : '"dobutamine stress echo"'
    } template`,
    view: `"${
      templateData.templateName ? templateData.templateName : ""
    }" Report Template`,
  };

  const textAreaFieldNames = {
    free: [
      { fieldName: "testConclusion", label: "Test Conclusion" },
      { fieldName: "contrastAgentNotes", label: "Contrast Agent Notes" },
      { fieldName: "lvSizeAndFunction", label: "Left Ventricle" },
      { fieldName: "rvPaPv", label: "Right Ventricle" },
      { fieldName: "aortaAndAv", label: "Aortic Valve and Pulmonic Valve" },
      { fieldName: "mvAndTv", label: "Mitral Valve and Tricuspid Valve" },
      {
        fieldName: "laAndRa",
        label: "Left Atrium, Right Atrium, Pulmonary Veins",
      },
      { fieldName: "vessels", label: "Vessels" },
    ],
    stress: [
      { fieldName: "testConclusion", label: "Test Conclusion" },
      { fieldName: "contrastAgentNotes", label: "Contrast Agent Notes" },
      // common stress echo fields
      { fieldName: "stressTestProtocol", label: "Stress Test Protocol" },
      { fieldName: "reasonForStopping", label: "Reason for stopping" },
      { fieldName: "functionalCapacity", label: "Functional Capacity" },
      {
        fieldName: "hemodynamicExerciseResponse",
        label: "Hemodynamic Response to Exercise",
      },
      // Physiological stress echo fields
      { fieldName: "baselineECG", label: "Resting ECG" },
      { fieldName: "peakExerciseECG", label: "Peak-exercise ECG" },
      { fieldName: "recoveryECG", label: "Recovery ECG" },
      { fieldName: "restingStressEcho", label: "Resting Echo" },
      { fieldName: "peakExerciseStressEcho", label: "Peak Exercise Echo" },
      { fieldName: "recoveryStressEcho", label: "Recovery Echo" },
    ],
    dobutamine: [
      { fieldName: "testConclusion", label: "Test Conclusion" },
      { fieldName: "pharmacologicalNotes", label: "Pharmacological Notes" },
      { fieldName: "contrastAgentNotes", label: "Contrast Agent Notes" },
      // common stress echo fields
      { fieldName: "stressTestProtocol", label: "Stress Test Protocol" },
      { fieldName: "reasonForStopping", label: "Reason for stopping" },
      { fieldName: "functionalCapacity", label: "Functional Capacity" },
      {
        fieldName: "hemodynamicExerciseResponse",
        label: "Hemodynamic Response to Exercise",
      },
      // Dobutamine (pharmacological) stress echo fields
      { fieldName: "restingECGEcho", label: "Resting ECG/Echo" },
      {
        fieldName: "lowDoseDobutamineECGEcho",
        label: "Low Dose Dobutamine ECG/Echo",
      },
      {
        fieldName: "peakDoseDobutamineECGEcho",
        label: "Peak Dose Dobutamine ECG/Echo",
      },
      { fieldName: "recoveryECGEcho", label: "Recovery ECG/Echo" },
    ],
  };

  return (
    <div>
      {(formState.action !== "view" ||
        (formState.action === "view" && formState.templateId)) && (
        <Heading as="h2" size="lg">
          {headingText[formState.action]}
        </Heading>
      )}
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            const bodyData = {
              templateName: values.templateName,
              templateType: formState.templateType,
            };

            if (formState.templateType === "free") {
              bodyData.templateText = {
                testConclusion: values.testConclusion,
                contrastAgentNotes: values.contrastAgentNotes,
                lvSizeAndFunction: values.lvSizeAndFunction,
                rvPaPv: values.rvPaPv,
                laAndRa: values.laAndRa,
                aortaAndAv: values.aortaAndAv,
                mvAndTv: values.mvAndTv,
                vessels: values.vessels,
              };
            } else if (formState.templateType === "stress") {
              bodyData.templateText = {
                testConclusion: values.testConclusion,
                contrastAgentNotes: values.contrastAgentNotes,
                stressTestProtocol: values.stressTestProtocol,
                reasonForStopping: values.reasonForStopping,
                functionalCapacity: values.functionalCapacity,
                hemodynamicExerciseResponse: values.hemodynamicExerciseResponse,
                baselineECG: values.baselineECG,
                peakExerciseECG: values.peakExerciseECG,
                recoveryECG: values.recoveryECG,
                restingStressEcho: values.restingStressEcho,
                peakExerciseStressEcho: values.peakExerciseStressEcho,
                recoveryStressEcho: values.recoveryStressEcho,
              };
            } else if (formState.templateType === "dobutamine") {
              bodyData.templateText = {
                testConclusion: values.testConclusion,
                contrastAgentNotes: values.contrastAgentNotes,
                stressTestProtocol: values.stressTestProtocol,
                reasonForStopping: values.reasonForStopping,
                functionalCapacity: values.functionalCapacity,
                hemodynamicExerciseResponse: values.hemodynamicExerciseResponse,
                restingECGEcho: values.restingECGEcho,
                lowDoseDobutamineECGEcho: values.lowDoseDobutamineECGEcho,
                peakDoseDobutamineECGEcho: values.peakDoseDobutamineECGEcho,
                recoveryECGEcho: values.recoveryECGEcho,
                pharmacologicalNotes: values.pharmacologicalNotes,
              };
            }

            const existingTemplateNameFlag = reportTemplates.some(
              (et) =>
                et.templateName.toLowerCase() ===
                values.templateName.trim().toLowerCase()
            );

            function handleFormSubmission(result) {
              setFormSubmitSuccess(true);
              resetForm({ values: {} });
              setFormState({
                templateId: result.templateId,
                action: "view",
                templateType: formState.templateType,
              });

              setTimeout(() => {
                setFormSubmitSuccess(false);
              }, 3500);
            }

            if (formState.action === "edit") {
              updateTemplateMutation.mutate(
                { body: bodyData, id: templateData.templateId },
                {
                  onSuccess: (data) => {
                    handleFormSubmission(data);
                  },
                }
              );
            } else if (!existingTemplateNameFlag) {
              createTemplateMutation.mutate(bodyData, {
                onSuccess: (data) => {
                  handleFormSubmission(data);
                },
              });
            } else {
              setTemplateNameError(true);
              setTimeout(() => {
                setTemplateNameError(false);
              }, 2000);
            }
          }}
        >
          <Form>
            <div>
              <div>
                {formState.action !== "view" && (
                  <TextInput
                    name="templateName"
                    label="Template Name"
                    placeholder="Template Name"
                  />
                )}
                <Grid templateColumns="repeat(2, 1fr)">
                  {(formState.templateId || formState.action !== "view") &&
                    textAreaFieldNames[formState.templateType]?.map((t) => (
                      <TextAreaField
                        key={t.fieldName}
                        name={t.fieldName}
                        label={t.label}
                      />
                    ))}
                </Grid>
              </div>
            </div>
            {formState.action !== "view" && (
              <div>
                <PrimaryButton type="submit" style={{ margin: "0 1rem" }}>
                  SUBMIT
                </PrimaryButton>
                <SecondaryButton
                  type="button"
                  onClick={() =>
                    setFormState((prevState) => ({
                      ...prevState,
                      action: "view",
                    }))
                  }
                >
                  CANCEL
                </SecondaryButton>
              </div>
            )}
          </Form>
        </Formik>
        {formSubmitSuccess ? (
          <AlertMessage status="success" message="Form saved successfully" />
        ) : null}
      </div>
    </div>
  );
};

const AlertMessage = ({ status, message }) => {
  return (
    <Alert status={status} w="25vw" mt="1rem">
      <AlertIcon />
      {message}
    </Alert>
  );
};

const ReportTemplateSelector = ({
  reportTemplates,
  formState,
  setFormState,
}) => {
  const handleClick = (action) => {
    setFormState((prevState) => ({ ...prevState, action: action }));
  };

  const handleChange = (name, event) => {
    const { value } = event.target;

    if (value === "add") {
      setFormState((prevState) => ({
        ...prevState,
        action: "create",
        templateId: "",
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        action: "view",
        templateId: "",
        [name]: value,
      }));
    }
  };

  const SelectInput = ({ label, name, ...props }) => {
    return (
      <Flex justifyContent="space-around" flexDirection="column">
        <FormLabel
          width="18vw"
          fontSize="20px"
          fontFamily="Jost"
          lineHeight="29px"
          htmlFor={props.id || name}
        >
          {label}
        </FormLabel>
        <Select
          width="20vw"
          id={name}
          {...props}
          height="42px"
          boxSizing="border-box"
          borderRadius="14px"
          border="2px solid #d3d3d3"
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
          onChange={(event) => handleChange(name, event)}
          value={formState[name]}
        />
      </Flex>
    );
  };

  return (
    <div>
      <Heading as="h1" size="xl" pb="2rem">
        Manage Report Templates
      </Heading>
      <Grid templateColumns="20vw" rowGap="4vh">
        <SelectInput label="Select Template Type" name="templateType">
          <option value="">Select a template type</option>
          <option value="free">Free form</option>
          <option value="stress">Physiological Stress echo</option>
          <option value="dobutamine">Pharmacological Stress echo</option>
        </SelectInput>

        <SelectInput label="Select Report Template" name="templateId">
          <option value="">Select a report template</option>
          {reportTemplates
            .filter((rt) => rt.templateType === formState.templateType)
            .map((rt) => (
              <option key={rt.templateId} value={rt.templateId}>
                {rt.templateName}
              </option>
            ))}
          {formState.templateType && <option value="add">Add new</option>}
        </SelectInput>

        <Flex width="12vw" justifyContent="space-between" alignItems="flex-end">
          {formState.templateId && (
            <>
              <Button colorScheme="cyan" onClick={() => handleClick("edit")}>
                Edit
              </Button>
              <Button colorScheme="yellow" onClick={() => handleClick("copy")}>
                Copy
              </Button>
            </>
          )}
        </Flex>
      </Grid>
    </div>
  );
};

const ManageReportTemplates = () => {
  const history = useHistory();
  const { data: reportTemplates } = useGetAllQuery("templates");
  const [formState, setFormState] = useState({ action: "view" });

  if (!reportTemplates) {
    return null;
  }

  return (
    <div style={{ width: "95vw", margin: "5vh auto", minHeight: "60vh" }}>
      {/*  <SecondaryButton
        onClick={history.goBack}
        style={{ marginBottom: "2rem" }}
      >
        GO BACK
      </SecondaryButton>
    */}
      <Grid templateColumns="25vw 65vw" columnGap="5vw">
        <ReportTemplateSelector
          reportTemplates={reportTemplates}
          setFormState={setFormState}
          formState={formState}
        />
        <AddReportTemplate
          reportTemplates={reportTemplates}
          formState={formState}
          setFormState={setFormState}
        />
      </Grid>
    </div>
  );
};

export default ManageReportTemplates;
