import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Gravatar from "react-gravatar";

import { Menu, MenuItem, MenuButton, MenuList, Box } from "@chakra-ui/react";
import { MdHome, MdPerson, MdExitToApp } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";

const AccountButton = ({ user }) => {
  const history = useHistory();

  async function signOut() {
    try {
      await Auth.signOut();
      history.push("/signin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <>
      <Menu>
        <MenuButton>
          <Gravatar email={user.email || ""} style={{ borderRadius: "50%" }} />
        </MenuButton>
        <MenuList fontSize="16px" color={"black"}>
          <MenuItem as={RouterLink} to="/">
            <Box w="2.5rem" mr="1rem">
              <MdHome />
            </Box>
            Home
          </MenuItem>
          <MenuItem as={RouterLink} to="/profile">
            <Box w="2.5rem" mr="1rem">
              <MdPerson />
            </Box>
            Profile {/* - {user.email} */}
          </MenuItem>
          <MenuItem as={RouterLink} to="/audit">
            <Box w="2.5rem" mr="1rem">
              &#9878;
            </Box>
            Audit
          </MenuItem>
          <MenuItem as={RouterLink} to="/referringphysicians">
            <Box w="2.5rem" mr="1rem">
              <span role="img" aria-label="physicians icon">
                👩‍⚕️👨‍⚕️
              </span>
            </Box>
            Referring Physicians
          </MenuItem>
          <MenuItem as={RouterLink} to="/reporttemplates">
            <Box w="2.5rem" mr="1rem">
              <span role="img" aria-label="paper icon">
                📄
              </span>
            </Box>
            Report Templates
          </MenuItem>
          <MenuItem as={RouterLink} to="/normalvalues">
            <Box w="2.5rem" mr="1rem">
              <FaRegChartBar />
            </Box>
            Normative Values
          </MenuItem>
          <MenuItem as={RouterLink} to="/macros">
            <Box w="2.5rem" mr="1rem">
              <FaRegChartBar />
            </Box>
            Macros
          </MenuItem>
          <MenuItem onClick={() => signOut()}>
            <Box w="2.5rem" mr="1rem">
              <MdExitToApp />
            </Box>
            Signout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default AccountButton;
