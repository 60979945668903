import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="logo" />
    <p
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem 0 .5rem",
      }}
    >
      &nbsp; Augmented Reporting &nbsp;&nbsp;
      <a
        href="https://aws.amazon.com/what-is-cloud-computing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          width="100"
          src="https://d0.awsstatic.com/logos/powered-by-aws.png"
          alt="Powered by AWS Cloud Computing"
          loading="lazy"
        />
      </a>
    </p>
  </footer>
);

export default Footer;
