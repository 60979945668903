// import { findLocalItems } from "../utils/util";
import Cookies from "js-cookie";
import { getCurrentSession } from "../services/auth.service";

export async function fetchPatients() {
  const FORM_API_URL = process.env.REACT_APP_FORM_API_URL + "/series";

  // ensure fresh tokens
  const session = await getCurrentSession();

  // if no user logged in
  if (session == null) {
    var strClientAccessToken = Cookies.get("ClientAccessToken");
  } else strClientAccessToken = session.accessToken.jwtToken;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    accesstoken: strClientAccessToken,
  };

  /*  
  const oldAPIPatients = await fetch(API_URL, {
    method: "GET",
    headers: { ...headers /* apikey: process.env.REACT_APP_EXTAPI_KEY */ /* },
  })
    .then(handleErrors)
    //    .then((response) => response.json())
    .then((response) => (response.length ? response.json() : []))
    .catch((error) => {
      console.log("error", error);
      return error;
    });
*/

  // for testing  const newFormAPIPatients = [];

  const newFormAPIPatients = await fetch(FORM_API_URL, {
    method: "GET",
    headers: {
      ...headers,
    },
    /*    credentials: 'include' // include cookies in the request */
  })
    .then(handleErrors)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  if (
    newFormAPIPatients instanceof Error /* || oldAPIPatients instanceof Error */
  ) {
    return [];
  }

  /*
  // Create an array of SeriesInstanceUID from the data from the new form API
  const newFormAPIPatientsSeriesArray = newFormAPIPatients.map(
    (patient) => patient.SeriesInstanceUID
  );

  // Filter out patients existing in the new form API from the old API - to prevent duplicates
  const filteredOldAPIPatients = oldAPIPatients.filter(
    (patient) =>
      !newFormAPIPatientsSeriesArray.includes(patient.SeriesInstanceUID)
  );
*/
  const patients = [/* ...filteredOldAPIPatients, */ ...newFormAPIPatients];

  // Source: https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
  // Sort based on SeriesDate then by SeriesTime
  const sortedPatients = patients.sort((a, b) =>
    a.SeriesDate > b.SeriesDate
      ? 1
      : a.SeriesDate === b.SeriesDate
      ? a.SeriesTime > b.SeriesTime
        ? 1
        : -1
      : -1
  );
  sortedPatients.reverse();

  return Promise.resolve(
    sortedPatients.map((patient) => {
      // calculate the slug
      patient.slug = patient.SeriesInstanceUID;
      patient.studyIUID = patient.StudyInstanceUID;

      return patient;
    })
  );
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
