export const LOAD_PATIENTS = "LOAD_PATIENTS";
export const LOAD_INGREDIENTS = "LOAD_INGREDIENTS";
export const LOAD_GLASSES = "LOAD_GLASSES";
export const DRAFT_REPORT = "DRAFT_REPORT";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const ACTIVATE_FILTER_DIALOG = "ACTIVATE_FILTER_DIALOG";
export const CLOSE_FILTER_DIALOG = "CLOSE_FILTER_DIALOG";
export const SET_BAR = "SET_BAR";
export const UPDATE_FAVOURITES = "UPDATE_FAVOURITES";
export const ADD_TO_BAR = "ADD_TO_BAR";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const TOGGLE_PRIDE = "TOGGLE_PRIDE";
export const TOGGLE_LINGO = "TOGGLE_LINGO";
export const START_ENRICH_COCKTAIL = "START_ENRICH_COCKTAIL";
export const FAIL_ENRICH_COCKTAIL = "FAIL_ENRICH_COCKTAIL";
export const FINISH_ENRICH_COCKTAIL = "FINISH_ENRICH_COCKTAIL";
