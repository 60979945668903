import React from "react";
import { Button } from "@chakra-ui/react";

const buttonStyles = {
  borderRadius: "10px",
  padding: ".5rem",
  fontFamily: "Jost",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "29px",
  letterSpacing: "0.02em",
  width: "fit-content",
  height: "fit-content",
};

const TemplateButton = (props) => {
  return (
    <>
      <Button
        variant="solid"
        size="md"
        {...props}
        style={{ ...buttonStyles, ...props.style }}
      ></Button>
    </>
  );
};

export const PrimaryButton = (props) => {
  return (
    <>
      <TemplateButton
        background="#0038ff"
        color="#ffffff"
        _hover={{ background: "#83b4ff" }}
        _disabled={{ background: "#7e7e7e" }}
        {...props}
      />
    </>
  );
};

export const SecondaryButton = (props) => {
  return (
    <>
      <TemplateButton
        color="#0038ff"
        background="white"
        border="1px solid #0038ff"
        _hover={{
          color: "#83b4ff",
          border: "1px solid #83b4ff",
          background: "white",
        }}
        _disabled={{
          color: "#7e7e7e",
          border: "1px solid #7e7e7e",
          background: "white",
        }}
        {...props}
      />
    </>
  );
};
