import * as actionTypes from "./actionTypes";

export function loadPatients(payload) {
  return { type: actionTypes.LOAD_PATIENTS, payload };
}

export function loadIngredients(payload) {
  return { type: actionTypes.LOAD_INGREDIENTS, payload };
}

export function loadGlasses(payload) {
  return { type: actionTypes.LOAD_GLASSES, payload };
}

export function updateFilter(payload) {
  return { type: actionTypes.UPDATE_FILTER, payload };
}

export function draftReport(payload) {
  return { type: actionTypes.DRAFT_REPORT, payload };
}

export function setBar(payload) {
  return { type: actionTypes.SET_BAR, payload };
}

export function activateFilterDialog(payload) {
  return { type: actionTypes.ACTIVATE_FILTER_DIALOG, payload };
}

export function closeFilterDialog() {
  return { type: actionTypes.CLOSE_FILTER_DIALOG };
}

export function updateFavourites(payload) {
  return { type: actionTypes.UPDATE_FAVOURITES, payload };
}

export function addToBar(payload) {
  return { type: actionTypes.ADD_TO_BAR, payload };
}

export function updateSettings(payload) {
  return { type: actionTypes.UPDATE_SETTINGS, payload };
}

export function togglePride() {
  return { type: actionTypes.TOGGLE_PRIDE };
}

export function toggleLingo() {
  return { type: actionTypes.TOGGLE_LINGO };
}
