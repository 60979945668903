import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import PrivateRoute from "./components/PrivateRoute";
import Topbar from "./components/Topbar";
import PatientBrowser from "./components/PatientBrowser";
import PatientPage from "./components/PatientPage";
import Profile from "./components/Profile";
import Footer from "./components/Footer";
import SignUpPage from "./components/Authentication/SignUpPage";
import SignIn from "./components/Authentication/SignIn";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import PDFGenerator from "./components/PDFGenerator";
import AuditReport from "./components/AuditReport";
import ManageReferringPhysicians from "./components/ManageReferringPhysicians";
import ManageReportTemplates from "./components/ManageReportTemplates";
import ManageNormalValues from "./components/ManageNormalValues";
import ManageMacros from "./components/ManageMacros";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./App.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider resetCSS={true}>
        <Router>
          <header>
            <Topbar />
          </header>
          <Switch>
            <PrivateRoute exact path={"/"} component={PatientBrowser} />
            <PrivateRoute exact path={"/patients"} component={PatientBrowser} />
            <PrivateRoute exact path={"/audit"} component={AuditReport} />
            <PrivateRoute
              exact
              path={"/referringphysicians"}
              component={ManageReferringPhysicians}
            />
            <PrivateRoute
              exact
              path={"/reporttemplates"}
              component={ManageReportTemplates}
            />
            <PrivateRoute
              exact
              path={"/normalvalues"}
              component={ManageNormalValues}
            />
            <PrivateRoute exact path={"/macros"} component={ManageMacros} />
            <Route path="/report/:slug" component={PDFGenerator} />
            <PrivateRoute path={"/patients/:slug"} component={PatientPage} />
            <PrivateRoute path="/profile" component={Profile} />
            <Route exact path={"/signup"} component={SignUpPage} />
            <Route exact path={"/signin"} component={SignIn} />
            <Route exact path={"/forgotpassword"} component={ForgotPassword} />
          </Switch>
          <Footer />
          <ReactQueryDevtools initialIsOpen />
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
