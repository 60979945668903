import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Grid, Avatar, Flex, Box } from "@chakra-ui/react";

import { getCurrentAuthenticatedUser } from "../services/auth.service";

import Highlight from "./Highlight";
import { SecondaryButton } from "./Buttons";

const Profile = () => {
  const history = useHistory();
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUser = async () => {
      const userData = await getCurrentAuthenticatedUser();

      if (userData && userData.attributes) {
        setUser(userData.attributes);
      }
    };

    getUser();
  }, []);

  return (
    <Grid>
      <SecondaryButton
        onClick={history.goBack}
        style={{ margin: "2rem 0 2rem 1rem" }}
      >
        GO BACK
      </SecondaryButton>
      <Grid gap="1vw" templateColumns="auto 3fr" p="1rem">
        <Avatar name={user.email} alt={user.email} />
        <Flex direction="column">
          <h2>{user.email}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Flex>
      </Grid>
      <Box p="1rem">
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Box>
    </Grid>
  );
};

export default Profile;
