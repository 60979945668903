import React, { useEffect, useState } from "react";
import { Form, Formik, useField } from "formik";
import { cloneDeep } from "lodash";

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";

import { PrimaryButton, SecondaryButton } from "./Buttons";

import {
  getAllNormalValueMeasurements,
  updateNormalValue,
} from "../services/form-api-service";

const DisplayMeasurementValue = ({ value }) => {
  return (
    <Flex direction="column" border="1px" borderColor="gray.200">
      <Flex borderBottom="1px" borderColor="gray.200" justify="space-evenly">
        <Heading as="h3" size="md" p=".5rem 0">
          Age {value.minAge}
          {value.maxAge > 200 ? "+" : "-" + value.maxAge} y
        </Heading>
      </Flex>
      <Flex justify="space-evenly" p=".5rem 0">
        <Box>Male</Box>
        <Box>Female</Box>
      </Flex>
      <Flex justify="space-evenly" p=".5rem 0">
        <Box>
          {value.M.value}±{value.M.sd}
        </Box>
        <Box>
          {value.F.value}±{value.F.sd}
        </Box>
      </Flex>
    </Flex>
  );
};

const CustomLabel = ({ htmlFor, label }) => {
  return (
    <FormLabel
      width="15vw"
      fontSize="20px"
      fontFamily="Jost"
      lineHeight="29px"
      htmlFor={htmlFor}
    >
      {label}
    </FormLabel>
  );
};

const MeasurementSelector = () => {
  const [normalValueMeasurements, setNormalValueMeasurements] = useState([]);
  const [selectedMeasurement, setSelectedMeasurement] = useState({});
  const [formState, setFormState] = useState({ mode: "view" });

  useEffect(() => {
    const getData = async () => {
      const allNormalValueMeasurements = await getAllNormalValueMeasurements();

      setNormalValueMeasurements(allNormalValueMeasurements);
    };

    getData();
  }, []);

  useEffect(() => {
    const measurement = normalValueMeasurements.find(
      (nvm) =>
        nvm.normalValueMeasurementId === formState.normalValueMeasurementId
    );

    setSelectedMeasurement(measurement);

    if (!formState.normalValueMeasurementId) {
      setFormState((prevFormState) => ({ ...prevFormState, mode: "view" }));
    }
  }, [formState.normalValueMeasurementId, normalValueMeasurements]);

  useEffect(() => {
    function getSelectedValueIndex() {
      const selectedValueIndex = normalValueMeasurements
        .find(
          (nvm) =>
            nvm.normalValueMeasurementId === formState.normalValueMeasurementId
        )
        .values.findIndex(
          (value) =>
            formState.minAge >= value.minAge && formState.minAge <= value.maxAge
        );

      return selectedValueIndex;
    }

    if (formState.minAge && formState.gender) {
      const selectedValueIndex = getSelectedValueIndex();
      const selectedValue = selectedMeasurement.values[selectedValueIndex];

      setFormState((prevFormState) => ({
        ...prevFormState,
        ...selectedValue[formState.gender],
        selectedValueIndex: selectedValueIndex,
      }));
    }
  }, [formState.minAge, formState.gender, selectedMeasurement?.values]);

  const handleChange = (event, name) => {
    setFormState({ ...formState, [name]: event.target.value });
  };

  const changeMode = (mode) => {
    setFormState({ ...formState, mode: mode });
  };

  const updateNormalValuesData = (updatedNormalValueData) => {
    const otherNormalValueMeasurements = normalValueMeasurements.filter(
      (nvm) =>
        updatedNormalValueData.normalValueMeasurementId !==
        nvm.normalValueMeasurementId
    );

    setNormalValueMeasurements([
      ...otherNormalValueMeasurements,
      updatedNormalValueData,
    ]);
  };

  const handleClick = async (values) => {
    const { selectedValueIndex, gender } = formState;
    const selectedMeasurementCopy = cloneDeep(selectedMeasurement);

    const selectedValueWithGender =
      selectedMeasurementCopy.values[selectedValueIndex][gender];

    selectedValueWithGender.value = parseFloat(values.value);
    selectedValueWithGender.sd = parseFloat(values.sd);

    const updatedNormalValueData = await updateNormalValue(
      selectedMeasurementCopy,
      selectedMeasurementCopy.normalValueMeasurementId
    );

    if (updatedNormalValueData.normalValueMeasurementId) {
      updateNormalValuesData(updatedNormalValueData);
      setFormState({ ...formState, mode: "view", minAge: "", gender: "" });
    }
  };

  const saveNormalSentence = async ({ normalValueSentence }) => {
    const updatedNormalValueData = await updateNormalValue(
      { normalValueSentence: normalValueSentence },
      selectedMeasurement.normalValueMeasurementId
    );

    if (updatedNormalValueData.normalValueMeasurementId) {
      updateNormalValuesData(updatedNormalValueData);
      return updatedNormalValueData;
    }

    return false;
  };

  const SelectInput = ({ label, ...props }) => {
    return (
      <Flex justifyContent="space-around" flexDirection="column">
        <CustomLabel htmlFor={props.id || props.name} label={label} />
        <Select
          width="35vw"
          {...props}
          height="42px"
          boxSizing="border-box"
          borderRadius="14px"
          border="2px solid #d3d3d3"
          value={formState[props.name]}
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
          onChange={(event) => handleChange(event, props.name)}
        />
      </Flex>
    );
  };

  return (
    <div style={{ margin: "2vh 0 5vh" }}>
      <Grid w="55vw" templateColumns="auto" gap={4}>
        <Grid
          templateColumns="35vw minmax(2rem, 5rem)"
          justify="start"
          alignItems="end"
          columnGap={2}
        >
          <SelectInput label="Measurement" name="normalValueMeasurementId">
            <option value="">Select a measurement</option>
            {normalValueMeasurements.map((nvm) => (
              <option
                key={nvm.normalValueMeasurementId}
                value={nvm.normalValueMeasurementId}
              >
                {nvm.normalValueMeasurementName}
              </option>
            ))}
          </SelectInput>
        </Grid>

        {formState.mode === "edit" && selectedMeasurement?.values && (
          <>
            <SelectInput label="Age range" name="minAge">
              <option value="">Select an age range</option>
              {selectedMeasurement?.values?.map((value, index) => (
                <option key={index} value={value.minAge}>
                  {value.minAge}
                  {value.maxAge > 200 ? "+" : `-${value.maxAge}`}
                </option>
              ))}
            </SelectInput>
            <SelectInput label="Gender" name="gender">
              <option value="">Select a gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </SelectInput>
            {formState.gender && (
              <ValuesForm formState={formState} handleClick={handleClick} />
            )}
          </>
        )}

        <Flex direction="column">
          {selectedMeasurement && (
            <>
              <Heading as="h2" size="lg" m="2rem 0 1rem 0">
                {selectedMeasurement.normalValueMeasurementName}
              </Heading>
              <Flex justify="flex-start" align="flex-end" m="1rem 0">
                <Heading as="h3" size="md" mr="2rem">
                  Normative values by age and gender
                </Heading>
                {formState.normalValueMeasurementId &&
                  formState.mode === "view" && (
                    <Button
                      size="md"
                      colorScheme="cyan"
                      onClick={() => changeMode("edit")}
                    >
                      EDIT VALUES
                    </Button>
                  )}
              </Flex>
            </>
          )}
          {selectedMeasurement?.values && (
            <Grid
              templateColumns="repeat(5, 1fr)"
              border="1px"
              borderColor="gray.200"
            >
              {selectedMeasurement.values.map((value, index) => (
                <DisplayMeasurementValue
                  value={value}
                  key={`${index}-${value.minAge}-${value.maxAge}`}
                />
              ))}
            </Grid>
          )}
          {selectedMeasurement && (
            <NormalValueTemplateSentence
              saveNormalSentence={saveNormalSentence}
              normalValueSentence={selectedMeasurement.normalValueSentence}
            />
          )}
        </Flex>
      </Grid>
    </div>
  );
};

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <Flex justifyContent="space-around" flexDirection="column">
      {label && <CustomLabel htmlFor={props.id || props.name} label={label} />}
      <Input
        {...field}
        {...props}
        width="35vw"
        height="42px"
        boxSizing="border-box"
        borderRadius="14px"
        border="2px solid #d3d3d3"
        padding="0.5rem 0.75rem"
        _focus={{ outline: "none", border: "2px solid #0038ff" }}
      />
      {meta.touched && meta.error ? <Box color="red">{meta.error}</Box> : null}
    </Flex>
  );
};

function ValuesForm({ formState, handleClick }) {
  if (!formState.value || !formState.sd) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        sd: formState ? formState.sd : "",
        value: formState ? formState.value : "",
      }}
      onSubmit={async (values) => {
        handleClick(values);
      }}
    >
      <Form>
        <TextInput name="value" label="Value" />
        <TextInput name="sd" label="Min Max -/+" />
        <PrimaryButton type="submit" style={{ marginTop: "1rem" }}>
          SAVE
        </PrimaryButton>
      </Form>
    </Formik>
  );
}

const NormalValueTemplateSentence = ({
  normalValueSentence,
  saveNormalSentence,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <>
      <Flex justify="flex-start" align="flex-end" m="4rem 0 1rem">
        <Heading as="h3" size="md" mr="2rem">
          Normative value template sentence
        </Heading>
        {!isEditMode && (
          <Button
            size="md"
            colorScheme="blue"
            onClick={() => setIsEditMode(true)}
          >
            {normalValueSentence ? "EDIT" : "CREATE"} NORMAL SENTENCE
          </Button>
        )}
      </Flex>

      {!isEditMode && normalValueSentence && (
        <>
          <Text fontSize="md">{normalValueSentence}</Text>

          {normalValueSentence.includes("/m") && (
            <Text fontSize="sm" pt=".5rem">
              /m will be replaced with the measurement value and unit.
            </Text>
          )}
        </>
      )}

      {isEditMode && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            normalValueSentence: normalValueSentence ? normalValueSentence : "",
          }}
          onSubmit={async (values) => {
            const updatedData = saveNormalSentence(values);

            if (updatedData) {
              setIsEditMode(false);
            }
          }}
        >
          <Form>
            <Flex alignItems="baseline">
              <TextInput name="normalValueSentence" label="" />
              <PrimaryButton type="submit" style={{ margin: "0 1rem" }}>
                SAVE
              </PrimaryButton>
              <SecondaryButton onClick={() => setIsEditMode(false)}>
                CANCEL
              </SecondaryButton>
            </Flex>
            <Text fontSize="sm">
              Add /m to include the measurement value and unit.
            </Text>
          </Form>
        </Formik>
      )}
    </>
  );
};

const ManageNormalValueMeasurements = () => {
  return (
    <div style={{ width: "95vw", margin: "5vh auto", minHeight: "60vh" }}>
      <Heading as="h1" size="xl" pb="1rem">
        Manage Normative Values
      </Heading>
      <MeasurementSelector />
    </div>
  );
};

export default ManageNormalValueMeasurements;
