import React from "react";
import { useField } from "formik";

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className="inputLabelSet">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="inputWithError">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        <input {...field} {...props} />
      </div>
    </div>
  );
};

export const AuthWrapper = ({ children, heading }) => {
  return (
    <div className="signUp authWrapper">
      <div>
        <img
          src="https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ARLogoIcon.png"
          alt="logo"
        />
        <h2>{heading}</h2>
        {children}
      </div>
    </div>
  );
};
