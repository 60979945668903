import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";

import { PrimaryButton } from "../Buttons";
import { AuthWrapper } from "./AuthComponents";
import "./authStyle.css";

async function signIn({ username, password }) {
  try {
    const user = await Auth.signIn(username, password);
    let strClientAccessToken = user.signInUserSession.accessToken.jwtToken;

    Cookies.set("ClientAccessToken", strClientAccessToken, {
      expires: 1, // matches Cognito appClient configuration
      sameSite: "None",
      secure: "true",
      //      domain: 'localhost'
    });

    return user;
  } catch (error) {
    console.log("error signing in", error);
    return error;
  }
}

const SignIn = () => {
  const history = useHistory();
  const [message, setMessage] = useState("");

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    // https://stackoverflow.com/questions/55451304/formik-yup-password-strength-validation-with-react
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 characters minimum")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one uppercase, one lowercase, one number and one special case character."
      ),
  });

  return (
    <AuthWrapper heading="Echo Augmented Reporting">
      <div className="signInForm authForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // Form inputs must be validated only on form submission
          // and not during input change and input blur
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values) => {
            const data = await signIn(values);

            if (data && data.attributes) {
              history.push("/");
            } else {
              setMessage(data.message);
            }
          }}
        >
          <Form>
            <CustomTextInput
              name="username"
              type="email"
              label="Email"
              placeholder="Enter your email"
            />
            <CustomTextInput
              name="password"
              type="password"
              label="Password"
              placeholder="Enter your password"
            />
            <div>
              <p>
                Forgot your password?&nbsp;
                <Link to="/forgotpassword">Reset password</Link>
              </p>
              <div className="signInSection authActionSection">
                <p>
                  No account?&nbsp;
                  <Link to="/signup">Create Account</Link>
                </p>
                <PrimaryButton type="submit">SIGN IN</PrimaryButton>
              </div>
            </div>
            <p style={{ color: "red" }}>{message}</p>
          </Form>
        </Formik>
      </div>
    </AuthWrapper>
  );
};

const CustomTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <div className="inputLabelSet">
      <label htmlFor={props.id || props.name}>
        {label}
        <span style={{ color: "red" }}>*</span>
      </label>
      <div className="inputWithError">
        {meta.touched && meta.error ? (
          <div style={{ color: "red" }}>{meta.error}</div>
        ) : null}
        <input {...field} {...props} />
      </div>
    </div>
  );
};

export default SignIn;
