import { useMutation } from "react-query";

import {
  updateClinic,
  updateReportTemplate,
  updateReferringPhysician,
  // updateNormalValueMeasurement,
} from "../services/form-api-service";

const updateFunctionsByCollection = {
  clinics: updateClinic,
  templates: updateReportTemplate,
  referringPhysicians: updateReferringPhysician,
  // 'normalValues': updateNormalValueMeasurement,
};

export function useUpdateMutation(collection, queryClient) {
  return useMutation(
    ({ body, id }) => {
      return updateFunctionsByCollection[collection](body, id);
    },
    { onSuccess: () => queryClient.invalidateQueries(collection) }
  );
}
