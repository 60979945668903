import React, { useEffect, useState } from "react";
import { Form, Formik, useField } from "formik";
import { cloneDeep } from "lodash";

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";

import { PrimaryButton, SecondaryButton } from "./Buttons";

import {
  createMacro,
  getAllMacros,
  updateMacro,
} from "../services/form-api-service";
import CopyToClipboard from "react-copy-to-clipboard";
import useToggle from "../hooks/useToggle";

const macroMeasurements = [
  // lvSizeAndFunction measurements
  "LVIDd",
  "LVIDs",
  "GLS",
  "IVSd",
  "PWd",
  "StrainA4",
  "EFSimpsons",
  "StrainA3",
  "LVOTDIAM",
  "LVEDV",
  "LVEDVx",
  "COx",
  "LVESV",
  "CO",
  "LVESVx",
  "SVx",
  "StrainA2",
  "EFTeich",
  "LVMass",
  "LVMassIndex",
  "RWT",
  "SVTeich",
  "SVTeichx",
  "COTeich",
  "COTeichx",
  "EFA4",
  "EFA2",
  "FS",
  "LVEDVA4",
  "LVEDVA4x",
  "LVESVA4",
  "COA4",
  "LVESVA4x",
  "COA4x",
  "EDVTeich",
  "SVA4",
  "SVA4x",
  "LVLsA4",
  "LVLdA4",
  "SVA2",
  "SVA2x",
  "LVLsA2",
  "LVESVA2",
  "COA2",
  "LVESVA2x",
  "COA2x",
  "LVLdA2",
  "LVEDVA2",
  "LVEDVA2x",
  "ESVTeich",
  "LVEDAA4",
  "LVEDAA2",
  "LVESAA4",
  "LVESAA2",

  // rvPaPv measurements
  "RVIDd",
  "TAPSE",
  "S",
  "TRPG",
  "RVB",
  "RVM",
  "RVL",
  "PVVel",
  "PVPG",
  "PVVTI",
  "QPQS",
  "AVSVx",
  "AVCOx",
  "RVOT",

  //laAndRa measurements
  "LAd",
  "LALsA4",
  "LAAsA4",
  "LALsA2",
  "LAAsA2",
  "LAVA4",
  "LAESV",
  "LAV",
  "RAa",
  "RAV",
  "s",
  "d",
  "a",
  "adur",
  "sdratio",
  "LAVI",
  "RAVI",
  "LAVA2",
  "LAVIA4",
  "LAVIA2",

  // aortaAndAv measurements
  "AO",
  "AscAo",
  "LAAO",
  "AVVmax",
  "AVPG",
  "AVMG",
  "AVAVmax",
  "AVAI",
  "AVAVTI",
  "AVVTI",
  "AVAIVTI",
  "LVOTVmax",
  "DI",
  "ARPHT",
  "LVOTmaxPG",
  "LVOTVTI",
  "AVSV",
  "AVCO",

  // mvAndTv measurements
  "TRVel",
  "TRmaxPG",
  "E",
  "A",
  "DecT",
  "MVEA",
  "esep",
  "Eesep",
  "elat",
  "Eelat",
  "IVRT",
  "Ee",
  "MVPHT",
  "MVTRCE",
  "MVMRERO",
  "RASP",
  "RVSP",
  "IVC",
  "TRMV",
  "TRMG",
  "PAP",

  // Diagnoses
  "DFA",
];

const ManageMacros = () => {
  const [macros, setMacros] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const macrosData = await getAllMacros();

      setMacros(macrosData);
    };

    getData();
  }, []);

  return (
    <div style={{ width: "95vw", margin: "5vh auto", minHeight: "60vh" }}>
      <Heading as="h1" size="xl" pb="1rem">
        Manage Macros
      </Heading>
      <Grid templateColumns="repeat(3, 30vw)" gap="4vh 2vw">
        <Grid gridTemplateColumns="1fr 8vw 2fr">
          <Heading size="sm">Measurement</Heading>
          <Heading size="sm">Macro code</Heading>
        </Grid>
        <Grid gridTemplateColumns="1fr 8vw 2fr">
          <Heading size="sm">Measurement</Heading>
          <Heading size="sm">Macro code</Heading>
        </Grid>
        <Grid gridTemplateColumns="1fr 8vw 2fr">
          <Heading size="sm">Measurement</Heading>
          <Heading size="sm">Macro code</Heading>
        </Grid>
      </Grid>
      <Grid templateColumns="repeat(3, 30vw)" gap="4vh 2vw">
        {macroMeasurements.map((m) => (
          <MacroComponent key={m} macroKey={m} macros={macros} />
        ))}
      </Grid>
    </div>
  );
};

const TextInput = ({ value, handleChange, ...props }) => {
  return (
    <Flex pb="0rem" justifyContent="space-around">
      <Box>
        <Input
          {...props}
          value={value}
          height="42px"
          boxSizing="border-box"
          borderRadius="14px"
          border="2px solid #d3d3d3"
          padding="0.5rem 0.75rem"
          onChange={handleChange}
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
        />
      </Box>
    </Flex>
  );
};

const MacroComponent = ({ macroKey, macros }) => {
  const [macroCode, setMacroCode] = useState("");
  const [currentMacro, setCurrentMacro] = useState({});
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isCopied, setIsCopied] = useToggle(false);

  useEffect(() => {
    const macro = macros.find((m) => m.measurementName === macroKey);

    if (macro) {
      setCurrentMacro(macro);
      setMacroCode(macro.macroCode);
    }
  }, [macros]);

  const handleChange = (event) => {
    const { value } = event.target;
    setMacroCode(value?.toLowerCase());
  };

  const handleClick = () => {
    setIsCreateMode((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    const bodyData = {
      measurementName: macroKey,
      macroCode: macroCode,
    };

    let updatedData = null;

    if (currentMacro.macroId) {
      updatedData = await updateMacro(bodyData, currentMacro.macroId);
    } else {
      updatedData = await createMacro(bodyData);
    }

    if (updatedData._id) {
      setIsCreateMode(false);
      setCurrentMacro(updatedData);
      // setMacros(prevState => [...prevState, updatedData]);
    }
  };

  const handleClickCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <Flex
      flexDirection="column"
      key={macroKey}
      padding="1rem"
      borderRadius="10px"
      border="1px solid #AAA"
    >
      <Grid alignItems="center" gridTemplateColumns="1fr 8vw 2fr">
        <Text>{macroKey}</Text>
        <Box>
          {isCreateMode ? (
            <TextInput value={macroCode} handleChange={handleChange} />
          ) : (
            <p>{currentMacro?.macroCode}</p>
          )}
        </Box>
        <Box justifySelf="center">
          {isCreateMode ? (
            <Grid templateColumns="auto auto" columnGap="1rem">
              <SecondaryButton onClick={handleSubmit}>SAVE</SecondaryButton>
              <PrimaryButton onClick={handleClick}>CANCEL</PrimaryButton>
            </Grid>
          ) : (
            <SecondaryButton onClick={handleClick}>
              {currentMacro?.measurementName ? "EDIT" : "CREATE"}
            </SecondaryButton>
          )}
        </Box>
      </Grid>
      {isCreateMode && !currentMacro.macroCode && (
        <Box>
          <p>
            Suggested macro code: /{macroKey?.toLowerCase()}&nbsp;
            <CopyToClipboard
              text={`/${macroKey?.toLowerCase()}`}
              onCopy={handleClickCopy}
            >
              <span style={{ cursor: "pointer" }}>📋</span>
            </CopyToClipboard>
          </p>
          <Text color="blue">{isCopied ? "Copied✅" : ""}</Text>
        </Box>
      )}
    </Flex>
  );
};

export default ManageMacros;
