// import { arrayContainsArray } from "./util";
// import { getIngredientKeys } from "./cocktail.utils";

import { customDateFormat } from "./util";

/**
 * Patient filtering rules
 *
 * each rule basically needs to return 'true' if the patient should
 * be returned. Adding an export here will automatically make it
 * applyable as a filter so long as an equivalent entry exists
 * in the 'filterConfig'.
 *
 */

export function nameIncludes(patient, { searchText }) {
  return patient.PatientName.toLowerCase().includes(searchText.toLowerCase());
}

// patient will be returned if the passed-in property is truthy
export function mustHaveTruthyProperty(patient, { property }) {
  // console.log("truthy", !!patient[property.toString()]);
  return !!patient[property.toString()];
}

export function mustHaveStudyStatus(patient, { property, value }) {
  // console.log("property, value: ", property, value);
  return patient[property.toString()] === value;
}

export function mustIncludeValue(patient, { property, value }) {
  // console.log("property, value: ", property, value);
  return patient[property]?.toLowerCase().includes(value);
}

export function todaysStudies(patient, { property }) {
  // customDateFormat returns a date in the form mmm-dd-yyyy, example: Dec-31-2019
  // Replace all the dashes in the returned date with spaces, example: Dec 31 2019
  const dateString = customDateFormat(patient[property]).replaceAll("-", " ");
  // Get today's date, example: Wed Apr 07 2021 19:35:01 GMT-0400 (Eastern Daylight Time)
  // Convert date to a string, find out if the dateString is a part of today's date
  return new Date().toString().includes(dateString);
}

/*
// cocktail will be returned if it includes all of the ingredients
// in the filter - NONE can be missing.
export function makeableFrom(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return false;
  return arrayContainsArray(ingredients, cocktailIngredients);
}

// cocktail will be returned if it includes all of the
// ingredients in the filter - SOME can be missing.
export function mustInclude(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return true;
  return arrayContainsArray(cocktailIngredients, ingredients);
}

// cocktail will be returned if it contains any of the
// ingredients from the filter.
export function canInclude(cocktail, { ingredients }) {
  const cocktailIngredients = getIngredientKeys(cocktail);
  if (ingredients.length === 0) return true;
  return cocktailIngredients.some((i) => ingredients.includes(i));
}

// cocktail will be returned if it contains NONE of the ingredients
// from the filter.
export function mustNotInclude(cocktail, { ingredients }) {
  return !canInclude(cocktail, { ingredients });
}

export function inGlass(cocktail, { glasses }) {
  return glasses.length === 0 || glasses.includes(cocktail.glass);
}
*/

export function inCategory(patient, { categories }) {
  return categories.length === 0 || categories.includes(patient.category);
}

export function isFavourite(patient, { favourites }) {
  return favourites.includes(patient.slug);
}
