import React, { useState } from "react";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { PrimaryButton } from "../Buttons";
import { TextInput, AuthWrapper } from "./AuthComponents";

const ForgotPassword = ({ setCurrentStage }) => {
  const [message, setMessage] = useState("");

  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
  });

  const forgotPassword = ({ username }) => {
    // Send confirmation code to user's email
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        setCurrentStage("forgot password submit");
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Username/client id combination not found.") {
          setMessage("Email address not found.");
        } else {
          setMessage(error.message);
        }
      });
  };

  return (
    <AuthWrapper heading="Forgot password">
      <div className="authForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            forgotPassword(values);
          }}
        >
          <Form>
            <TextInput
              name="username"
              id="username"
              type="email"
              label="Email"
              placeholder="Please enter your email"
            />
            <div className="authActionSection alignButtonRight">
              <PrimaryButton type="submit">SUBMIT</PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
      <p style={{ color: "red" }}>{message}</p>
    </AuthWrapper>
  );
};

const ForgotPasswordSubmit = () => {
  const history = useHistory();
  const [message, setMessage] = useState("");

  const initialValues = {
    username: "",
    code: "",
    new_password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    code: Yup.string()
      .required("Code is required")
      .matches(/^\d+$/, "Code can contain only numbers"),
    // https://stackoverflow.com/questions/55451304/formik-yup-password-strength-validation-with-react
    new_password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 characters minimum")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one uppercase, one lowercase, one number and one special case character."
      ),
  });

  const forgotPasswordSubmit = ({ username, code, new_password }) => {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        console.log(data);
        history.push("/signin");
      })
      .catch((error) => {
        console.log(error);
        setMessage(error.message);
      });
  };

  return (
    <AuthWrapper heading="Create new password">
      <div className="authForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            forgotPasswordSubmit(values);
          }}
        >
          <Form>
            <TextInput
              name="username"
              id="username"
              type="email"
              label="Email"
              placeholder="Enter your email"
            />
            <TextInput
              name="code"
              id="code"
              label="Code"
              placeholder="Enter the confirmation code"
            />
            <TextInput
              name="new_password"
              id="new_password"
              type="password"
              label="New password"
              placeholder="Enter the new password"
            />
            <div className="authActionSection alignButtonRight">
              <PrimaryButton type="submit">SUBMIT</PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
      <p style={{ color: "red" }}>{message}</p>
    </AuthWrapper>
  );
};

const ForgotPasswordPage = () => {
  const [currentStage, setCurrentStage] = useState("forgot password"); // "forgot password" and "forgot password submit" are the two stages

  if (currentStage === "forgot password") {
    return <ForgotPassword setCurrentStage={setCurrentStage} />;
  }

  if (currentStage === "forgot password submit") {
    return <ForgotPasswordSubmit />;
  }
};

export default ForgotPasswordPage;
