import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { capitalize } from "lodash";

import { Box, Heading, Select } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  customDateAndTimeFormat,
  getDateFromDateTimeString,
} from "../utils/util.js";

import { useGetAllQuery } from "../hooks/useGetAllQuery";

import { SecondaryButton } from "./Buttons.jsx";

import "./AuditReport.css";

const AuditReport = () => {
  const defaultSelection = {
    patientId: "",
    userEmail: "",
    accessType: "",
    isDateSelected: false,
  };
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selection, setSelection] = useState({ ...defaultSelection });
  const { data: patients } = useGetAllQuery("seriesForAudit");

  useEffect(() => {
    if (patients) {
      setUsers(getAllUsers(patients));
    }
  }, [patients]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelection({ ...defaultSelection, isDateSelected: true });
  };

  if (!patients) {
    return null;
  }

  return (
    <div className="auditPage">
      <SecondaryButton
        onClick={history.goBack}
        style={{ marginBottom: "2rem" }}
      >
        GO BACK
      </SecondaryButton>
      <Heading as="h1" fontSize="48px">
        Audit Report
      </Heading>

      <div className="selectField">
        <p>By patient</p>
        <Select
          id="patient"
          value={selection.patientId}
          onChange={(e) =>
            // Spread the defaultSelection selection object to reset all other values
            // Then set (overwrite) the specific value for this select box
            setSelection({ ...defaultSelection, patientId: e.target.value })
          }
          height="42px"
          minWidth="10vw"
          borderRadius="14px"
          boxSizing="border-box"
          border="2px solid #d3d3d3"
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
        >
          <option value="">Select a patient</option>
          {patients.map((patient) => (
            <option
              value={patient.SeriesInstanceUID}
              key={patient.SeriesInstanceUID}
            >
              {patient.PatientName}
            </option>
          ))}
        </Select>
      </div>

      <div className="selectField">
        <p>By user</p>
        <Select
          id="user"
          value={selection.userEmail}
          onChange={(e) =>
            setSelection({ ...defaultSelection, userEmail: e.target.value })
          }
          height="42px"
          minWidth="10vw"
          borderRadius="14px"
          boxSizing="border-box"
          border="2px solid #d3d3d3"
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
        >
          <option value="">Select a user</option>
          {users.map((user, index) => (
            <option value={user} key={user}>
              {user}
            </option>
          ))}
        </Select>
      </div>

      <div className="selectField">
        <p>By access type</p>
        <Select
          id="accessType"
          value={selection.accessType}
          onChange={(e) =>
            setSelection({ ...defaultSelection, accessType: e.target.value })
          }
          height="42px"
          minWidth="10vw"
          borderRadius="14px"
          boxSizing="border-box"
          border="2px solid #d3d3d3"
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
        >
          {/* The value fields match the accessType options set in the backend */}
          <option value="">Select an access type</option>
          <option value="view">Viewed</option>
          <option value="update">Updated</option>
          <option value="sign">Signed</option>
          <option value="archive">Archived</option>
          <option value="amend">Amended</option>
          <option value="echo viewer open">Echo viewer opened</option>
        </Select>
      </div>

      <div className="selectField datePicker">
        <p>By date</p>
        {/* <Box p=".5rem" border="1px solid #E2E8F0" borderRadius="md"> */}
        <DatePicker
          selected={selectedDate}
          dateFormat="MMM dd"
          onChange={(date) => handleDateChange(date)}
        />
        {/* </Box> */}
      </div>

      {selection.patientId && (
        <div style={{ paddingTop: "3rem" }}>
          <Heading as="h2" fontSize="36px">
            Audit details for&nbsp;
            {/* Find the patient by the SeriesInstanceUID, then display their name */}
            {
              patients.find(
                (patient) => patient.SeriesInstanceUID === selection.patientId
              ).PatientName
            }
          </Heading>
          {/* Find the patient by the SeriesInstanceUID, then map over it's audit array 
              to create a AuditDataField component for each element*/}
          {patients
            .find(
              (patient) => patient.SeriesInstanceUID === selection.patientId
            )
            .audit.map((elem) => (
              <AuditDataField data={elem} />
            ))}
        </div>
      )}

      {selection.userEmail && (
        <div style={{ paddingTop: "3rem" }}>
          <Heading as="h2" fontSize="36px">
            Audit details for user {selection.userEmail}
          </Heading>
          {/* Filter for patients who have audit array elements with the
              selected email address as the user who accessed the study.
              Create a new AuditByUserDataField for each of the filtered patients  */}
          {patients
            .map((patient) => ({
              PatientName: patient.PatientName,
              audit: patient.audit.filter(
                (elem) => elem.user === selection.userEmail
              ),
            }))
            .map((patient) => (
              <AuditByUserDataField data={patient} />
            ))}
        </div>
      )}

      {selection.accessType && (
        <div style={{ paddingTop: "3rem" }}>
          <Heading as="h2" fontSize="36px">
            Audit details for access type {capitalize(selection.accessType)}
          </Heading>
          {/* Filter for patients who have audit array elements with the selected
              accessType. Create a new AuditByUserDataField for each filtered patient*/}
          {patients
            .map((patient) => ({
              PatientName: patient.PatientName,
              audit: patient.audit.filter(
                (elem) => selection.accessType === elem.accessType
              ),
            }))
            .map((patient) => (
              <AuditByUserDataField data={patient} />
            ))}
        </div>
      )}

      {selection.isDateSelected && (
        <div style={{ paddingTop: "3rem" }}>
          <Heading as="h2" fontSize="36px">
            Audit details for {getDateFromDateTimeString(selectedDate)}
          </Heading>
          {/* Filter for patients who have audit array elements with the selected date
              as the date of access. Create a new AuditByUserDataField for each filtered patient*/}
          {patients
            .map((patient) => ({
              PatientName: patient.PatientName,
              audit: patient.audit.filter(
                (elem) =>
                  getDateFromDateTimeString(selectedDate) ===
                  getDateFromDateTimeString(elem.time)
              ),
            }))
            .map((patient) => (
              <AuditByUserDataField data={patient} />
            ))}
        </div>
      )}
    </div>
  );
};

const ChangeField = ({ changeKey, value }) => {
  if (
    typeof value.oldValue !== "string" ||
    typeof value.newValue !== "string"
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        width: "30vw",
        justifyContent: "space-between",
      }}
    >
      <p>{changeKey}</p>
      <p>{value.oldValue}</p>
      <p>{value.newValue}</p>
    </div>
  );
};

const AuditDataField = ({ data }) => {
  const time = customDateAndTimeFormat(data.time);
  const text = `${capitalize(data.accessType)}${
    data.accessType === "update" || data.accessType === "archive" ? "d" : "ed"
  } by`;

  return (
    <div
      style={{
        padding: "1rem",
        borderRadius: "10px",
        boxShadow: data.changes ? "0 0 5px rgba(0, 0, 0, .2)" : "",
      }}
    >
      <p>
        {text}: {data.user} [{capitalize(data.role)}] at {time}
      </p>
      {data.changes && <h4 style={{ marginBottom: 0 }}>Changes</h4>}
      {data.changes && (
        <div
          style={{
            display: "flex",
            width: "30vw",
            justifyContent: "space-between",
            marginBottom: 0,
          }}
        >
          <h5>Field</h5>
          <h5>Old value</h5>
          <h5>New value</h5>
        </div>
      )}
      {data.changes &&
        Object.keys(data.changes).map((element) => (
          <ChangeField changeKey={element} value={data.changes[element]} />
        ))}
    </div>
  );
};

const AuditByUserDataField = ({ data }) => {
  if (data.audit.length === 0) return null;

  return (
    <div style={{ paddingBlockEnd: "2rem" }}>
      <Heading as="h3" fontSize="24px">
        Patient name: {data.PatientName}
      </Heading>
      {data.audit.map((element) => (
        <AuditDataField data={element} />
      ))}
    </div>
  );
};

const getAllUsers = (patients) => {
  // Create an array of audit arrays from each patient then flatten the array
  // then create an array with the audit user emails
  const allUsers = patients
    .map((patient) => patient.audit)
    .flat()
    .map((audit) => audit.user);

  // Remove duplicates and put them in a array
  return [...new Set(allUsers)];
};

export default AuditReport;
